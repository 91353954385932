<template>
  <div class="content-wrapper">
    <div class="page-header">
      <h1>{{ $t('Human Resource Management') }} > {{ $t('Staffs') }} > {{ $t('Add') }}</h1>
    </div>
    <div class="page-content">
      <div class="employee main-content">
      <div class="page-header">
        <h1>Staff Information</h1>
        <StaffInfoForm :viewMode="false" :eventName="'addEmployee'" @addEmployee="addEmployee"/>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StaffInfoForm from '@/components/hr/StaffInfoForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'AddEmployee',
  components: {
    StaffInfoForm,
  },
  methods:{
    async addEmployee(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const employee = await Hr.createEmployee(this.apiUrl, form, loginInfo);
        this.$router.push('/staffs');
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
